import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as Icons from '@element-plus/icons'
import "lib-flexible-computer";
import './assets/index.less'

const app = createApp(App)

// 全局注册图标组件
const icons = Icons
for (const i in icons) {
    app.component(i, icons[i])
}

app.use(store).use(router).use(ElementPlus).mount('#app')
