import store from "@/store";
import * as ENVS from "@/env";

import { ElMessageBox } from "element-plus";
import { getToken, validateToken, removeAuth } from "@/utils/auth";
import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";

export let routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login"),
    meta: { title: ENVS.TITLE },
  },
  {
    path: "/idVerify",
    name: "IdVerify",
    component: () => import("@/views/idVerify"),
    meta: {
      title: ENVS.TITLE,
      requireAuth: true,
    },
  },
  {
    path: "/sso",
    name: "Sso",
    component: () => import("@/views/sso"),
    meta: {
      title: ENVS.TITLE,
      requireAuth: true,
    },
  },
  {
    path: "/404",
    name: "NotFound",
    component: () => import("@/views/404"),
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },
];

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
});

// 路由前置守卫
router.beforeEach((to, from, next) => {
  // 网页标题
  if (to.meta) {
    document.title = to.meta.title;
  }

  //从登录页跳转过来，store中有数据
  // 用户关闭页面时 token未过期的判断
  if (getToken()) {
    if (to.path === "/login") {
      if (store.state.loginInfo) {
        next("/sso");
      } else {
        store.commit("removeSsoToken");
      }
    } else {
      if (store.state.loginInfo) {
        next();
      } else {
        store.commit("removeSsoToken");
        next("/");
      }
    }
  }

  if (to.meta.requireAuth) {
    //刷新页面/
    var tokenKey = getToken();
    if (!tokenKey) {
      removeAuth();
      next("/login");
    } else {
      validateToken(tokenKey)
        .then(() => {
          store.commit("setSsoToken", tokenKey);
          next();
        })
        .catch(() => {
          ElMessageBox.alert('登录过期，请重新登录', '超时提醒', {
            confirmButtonText: '确定',
            callback: () => {
              removeAuth();
              next("/login");
            },
          })
        });
    }
  } else {
    if (to.path == "/login") {
      next();
    } else if (to.path == "/404") {
      next();
    } else {
      next("/");
    }
  }
});

export default router;
